import React, { useEffect, useState } from "react";
import { Card, Alert, Row, Spinner } from 'reactstrap';
import { If, Then, Else} from 'react-if'
import { useDispatch, useSelector } from "react-redux";
import { setCompetitiveInsightData, headerSummaryData, setFilteredItemList } from "../../../../../reducers/IntelReducer/IntelActions";
import CompetitiveInsightItemSortHeader from "./CompetitiveInsightItemSortHeader";
import useItemData from './useItemData';
import { isMobile } from "react-device-detect";
import { redirectFromDashboardToItemDetails } from "../../../../../reducers/DashboardReducer/DashboardActions";

const CompetitiveInsightItemView = ({ categoryDrilldown, itemDrilldown }) => {
  const { merchant, totalVolume, compItems, distItems, compDetailListMap, sortState, sortBy, distDetailListMap } = useItemData.useApi(categoryDrilldown, itemDrilldown);
  const redirectFromDashboardToItemDetailsStatus = useSelector(({ dashboard }) => dashboard.redirectFromDashboardToItemDetails);
  const merchantPurchasesTypeView = useSelector(({intel}) => intel.merchantPurchasesTypeView);
  const loaded = useSelector(({ intel }) => intel.allLoaded);
  const dispatch = useDispatch();
  let [alert, setAlert] = useState('');

  useEffect(() => {
    dispatch(setCompetitiveInsightData(compItems));
    dispatch(setFilteredItemList(merchantPurchasesTypeView.value === 'competition' ? compItems : distItems))
  }, [dispatch, compItems, distItems, merchantPurchasesTypeView]);

  useEffect(() => {
    dispatch(headerSummaryData(merchant, totalVolume));
  }, [dispatch, merchant, totalVolume]);

  useEffect(() => {
    if (redirectFromDashboardToItemDetailsStatus.redirecting) {
      setAlert("You've been redirected to item view, only one merchant available.")
    }
    window.scrollTo({
      top: 0,
    });
    dispatch(redirectFromDashboardToItemDetails(false));
    // eslint-disable-next-line
  }, []);


  return (
    <>
      {alert.length > 0 ? <Alert color="warning" toggle={() => setAlert('')} className="w-100 d-flex justify-content-center primary">{alert}</Alert> : false}
      {!isMobile && <CompetitiveInsightItemSortHeader sortState={sortState} sortBy={sortBy} />}
      <If condition={loaded}>
        <Then>
          <Card>
            {merchantPurchasesTypeView.value === 'competition' ? compDetailListMap : distDetailListMap }
          </Card>
        </Then>
        <Else>
          <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
        </Else>
      </If>

    </>
  );
}

export default CompetitiveInsightItemView;