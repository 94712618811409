export const setListSortBy = (key) => {
  return {
    type: 'SET_LIST_SORT_BY',
    payload: key
  }
};

export const setListSortByDesc = (key, desc) => {
  return {
    type: 'SET_LIST_SORT_BY_DESC',
    payload: {
      key: key,
      desc: desc
    }
  }
};

export const setDetailSortBy = (view, key, desc) => {
  return {
    type: 'SET_DETAIL_SORT',
    payload: {
      view, key, desc
    }
  }
};

export const setListPage = (page) => {
  return {
    type: 'SET_LIST_PAGE',
    payload: page
  }
};

export const setGridPage = (page) => {
  return {
    type: 'SET_GRID_PAGE',
    payload: page
  }
};

export const applySearch = () => {
  return {
    type: 'APPLY_INTEL_SEARCH'
  }
};


export const clearMerchants = () => {
  return {
    type: 'CLEAR_INTEL_MERCHANTS'
  }
};

export const setMerchants = (data) => {
  return {
    type: 'SET_INTEL_MERCHANTS',
    payload: data
  }
};

export const setToDate = (date) => {
  return {
    type: 'SET_INTEL_TO_DATE',
    payload: date
  }
};

export const setFromDate = (date) => {
  return {
    type: 'SET_INTEL_FROM_DATE',
    payload: date
  }
};

export const setDateRange = (payload) => {
  return {
    type: 'SET_INTEL_DATE_RANGE',
    payload
  }
}

export const setMerchantData = (data, activeDistributorId, finish) => {
  return {
    type: 'SET_INTEL_MERCHANT_DATA',
    payload: {
      data: data,
      activeDistributorId: activeDistributorId,
      finish: finish ? true : false
    }
  }
};

export const clearMerchantsData = () => {
  return {
    type: 'CLEAR_INTEL_MERCHANT_DATA',
  }
};

export const setSearch = (search) => {
  return {
    type: 'SET_INTEL_SEARCH',
    payload: search
  };
};

export const setIntelSearchAndSearchApply = (search) => {
  return {
    type: 'SET_INTEL_SEARCHES_ONLY',
    payload: search
  };
};

export const clearSearch = () => {
  return {
    type: 'CLEAR_INTEL_SEARCH',
  };
};

export const intelVisible = (visible) => {
  return {
    type: 'SET_INTEL_VISIBLE',
    payload: visible
  };
};

export const setCompetitiveInsightCategories = (cats) => {
  return {
    type: 'SET_COMPETITIVE_INSIGHT_CATEGORIES',
    payload: cats
  }
};

export const setCompetitiveInsightItems = (items) => {
  return {
    type: 'SET_COMPETITIVE_INSIGHT_ITEMS',
    payload: items
  }
};

export const setCompetitiveInsightData = (data) => {
  return {
    type: 'SET_COMPETITIVE_INSIGHT_DATA',
    payload: data
  }
};

export const setFilteredCompMerchantList = (data) => {
  return {
    type: 'SET_COMPETITIVE_FILTERED_MERCHANTS',
    payload: data
  }
};

export const setFilteredItemList = (data) => {
  return {
    type: 'SET_FILTERED_ITEMS',
    payload: data
  }
};

export const clearAllIntelData = () => {
  return {
    type: 'CLEAR_ALL_INTEL_DATA'
  };
}


export const itemMatchesData = (min, manufacturerPlatformId, data) => {
  return {
    type: 'ITEM_MATCHES_DATA',
    payload: {
      min: min,
      manufacturerPlatformId: manufacturerPlatformId,
      data: data
    }
  };
}

export const setItemMatches = (payload) => {
  return {
    type: 'SET_COMP_INSIGHTS_ITEM_MATCHES',
    payload
  };
}

export const setFilteredAndSortedItemMatches = (payload) => {
  return {
    type: 'SET_COMP_INSIGHTS_FILTERED_AND_SORTED_ITEM_MATCHES',
    payload
  };
}


export const headerSummaryData = (merchant, totalVolume) => {
  return {
    type: 'HEADER_SUMMARY_DATA',
    payload: {
      merchant,
      totalVolume
    }
  };
}

export const itemMatchesLoading = (state) => {
  return {
    type: 'COMP_INSIGHT_ITEM_MATCHES_LOADING',
    payload: state,
  }
}

export const setCompetitiveOrderHistoryPurchases = (data) => {
  return {
    type: 'SET_COMPETITIVE_ORDER_HISTORY_PURCHASES',
    payload: data
  }
}

export const setOrderItems = (payload) => {
  return {
    type: 'SET_COMP_INSIGHTS_ORDER_ITEMS',
    payload
  };
}

export const setOrderHistoryCalendarDate = (payload) => {
  return {
    type: 'SET_ORDER_HISTORY_CALENDAR_DATE',
    payload
  };
}

export const clearCompetitiveOrderHistoryPurchases = () => {
  return {
    type: 'CLEAR_COMPETITIVE_ORDER_HISTORY_PURCHASES'
  };
}

export const captureOrderHistoryMonth = (month, merchantId) => {
  return {
    type: 'CAPTURE_ORDER_HISTORY_MONTH',
    payload: { month, merchantId }
  };
}

export const clearOrderHistoryMonths = () => {
  return {
    type: 'CLEAR_ORDER_HISTORY_MONTHS'
  };
}

export const setLoadingOrderHistory = (payload) => {
  return {
    type: 'SET_LOADING_ORDER_HISTORY',
    payload
  };
}

export const setMerchantPurchasesTypeView = (type) => {
  return {
    type: 'SET_MERCHANT_PURCHASES_TYPE_VIEW',
    payload: type
  }
}