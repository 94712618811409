import React from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import numeral from 'numeral';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import stringSanitizer from 'string-sanitizer';

const CompetitiveInsightMerchantRow = ({ dcn, merchant, id, totalVolume, competitorVolume, competitorVolumePerc }) => {
  const history = useHistory()
  if (dcn === "") {
    dcn = "--";
  }

  const onClick = () => {
    merchant = stringSanitizer.sanitize.keepSpace(merchant);
    history.push('/competitiveinsights/items/' + merchant +  '/' + id);
  }
  if(isMobile) {
    return (
      <CardBody className='border-bottom p-3'>
        <Row className='pb-3'>
          <Col className='font-weight-bold text-capitalize'>
            {merchant}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Account Number</Col>
          <Col className='text-right'>
            {dcn}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Total Volume</Col>
          <Col className='text-right'>
          {numeral(totalVolume).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Competitor Volume</Col>
          <Col className='text-right'>
          {numeral(competitorVolume).format('$0,0.00')}
          </Col>
        </Row>
        <Row className='align-items-center py-2'>
          <Col className='w-fc text-muted'>Competitor Volume %</Col>
          <Col className='text-right'>
          {numeral(competitorVolumePerc).format('0.0 %')}
          </Col>
        </Row>
        <Row className='py-2'>
        <Col className={'see-details-label text-right text-' + (!competitorVolume && !totalVolume ? 'muted' : 'beplMagenta clickable')} onClick={!competitorVolume && !totalVolume ? null : onClick}>
            See Details
          </Col>
        </Row>
      </CardBody>
    )
  } else {
    return (
      <CardBody  className='border-top py-2 px-3 hover-row'>
        <Row className='align-items-center'>
          <Col>
            {dcn}
          </Col>
          <Col>
            {merchant}
          </Col>
          <Col>
            {numeral(totalVolume).format('$0,0.00')}
          </Col>
          <Col>
            {numeral(competitorVolume).format('$0,0.00')}
          </Col>
          <Col>
            {numeral(competitorVolumePerc).format('0.0 %')}
          </Col>
          <Col className={'see-details-label text-right text-' + (!competitorVolume && !totalVolume ? 'muted' : 'beplMagenta clickable')}  onClick={!competitorVolume && !totalVolume ? null : onClick}>
            See Details
          </Col>
        </Row>
      </CardBody>
    );
  }

}

export default CompetitiveInsightMerchantRow;