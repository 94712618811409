import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { showModal } from '../../../reducers/ModalsReducer/ModalsActions';
import { Container, Row, Button, Col, Card, Spinner } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import useRebateChecks from './useRebateChecks';
import Breadcrumbs from '../../../sharedComponents/Breadcrumbs/Breadcrumbs';
import ReactSelect from 'react-select';
import RebateCheckModal from './Subcomponents/RebateCheckModal';
import { useDispatch } from 'react-redux';
import RebateChecksTopChart from './Subcomponents/RebateChecksTopChart';
import { colors } from '../../../config/constants';
import { BsSearch } from 'react-icons/bs';
import { calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack } from '../../../sharedComponents/Helper/calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack';

const RebateCheckDetails = () => {
  const dispatch = useDispatch();
  const { toMerchantsBreakdown, fromManufBreakdown, check, loadStatus, loading, setTimeframe, timeframe } = useRebateChecks.useCheckDetails();
  const { exportCheckData } = useRebateChecks.useExportData();
  const { merchantid, merchant } = useParams();
  // const year = new Date().getFullYear();
  const showCheckModal = () => {
    dispatch(showModal(RebateCheckModal, {check}));
  }

  const exportData = () => {
    exportCheckData(merchantid, merchant)
    // exportCsv(toMerchantsBreakdown, fromManufBreakdown, `${timeframe} Check Details - ${check.accountname} .csv`);
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const breadcrumbsLinks = [
    {
      label: 'Payment Information',
      path: '/paymentinformation',
    },
    {
      label: timeframe.toUpperCase() + ' MAP Rebates',
    }
  ]

  let quarterOptions = calcWhatFiscalQtrAndYrForGivenAmountOfQtrsToLookBack(8);

  return (
    <Container fluid className='bg-grayBg flex-grow-1 px-4'>
      <Row className='m-0 px-0 py-2'>
        <Breadcrumbs links={breadcrumbsLinks} />
      </Row>
      <Row className="p-0 m-0">
        <Col className="m-0 p-0 mt-2" xs={isMobile ? 12 : 2}>
          <ReactSelect defaultValue={{ label: quarterOptions.find(op => op.value === timeframe).label, value: timeframe }} options={quarterOptions} onChange={setTimeframe} />
        </Col>
        {check && check.status ? <Col className="p-0 m-0">
          <Button onClick={showCheckModal} className={`${isMobile ? 'w-100' : 'ml-2'} mt-2 border-2 border-beplMagenta bg-transparent text-beplMagenta`}>View Voucher</Button>
          <Button onClick={exportData} className={`${isMobile ? 'w-100' : 'ml-2'} mt-2 border-2 border-beplMagenta bg-transparent text-beplMagenta`}>Export Data</Button>
        </Col> : false}

      </Row>
      {!loading && loadStatus === 'success' && (check) ?
        /////////////////////////////////////////////////////////////////
        <>

       

          <Row className="m-0 justify-content-between mt-3 text-size-30">
            {'$' + check.amount}
          </Row>
          <Row className="m-0 justify-content-between text-grayText text-size-14">
            Payment Amount
          </Row>

          <Row className={`m-0 p-0 mt-2 ${isMobile ? 'flex-column' : 'flex-row'}`}>
            <Col className={`m-0 py-0 pl-0 ${isMobile ? 'pr-0' : 'pr-2'}`} xs={isMobile ? 12 : 6}>
              <Card className="p-4">
                <Col className="d-flex justify-content-between p-0">
                  <div style={{ borderLeft: '5px solid' }} className='font-weight-bold border-beplBlue pl-1'>Locations</div>
                </Col>
                <RebateChecksTopChart leftSide={true} topData={toMerchantsBreakdown} />
              </Card>
            </Col>
            <Col className={`m-0 py-0 pr-0 ${isMobile ? 'mt-3 pl-0 mb-3' : 'pl-2'}`} xs={isMobile ? 12 : 6}>
              <Card className="p-4">
                <Col className="d-flex justify-content-between p-0">
                  <div style={{ borderLeft: '5px solid' }} className='font-weight-bold border-beplBlue pl-1'>Manufacturers</div>
                </Col>
                <RebateChecksTopChart topData={fromManufBreakdown} />
              </Card>
            </Col>
            <Col className="m-0 p-0" xs={6}></Col>
          </Row>
        </>
        : !loading && loadStatus === 'failure' ?
          /////////////////////////////////////////////////////////////////
          <Row style={{ position: 'relative', top: '90px' }}>
            <Col xs={12} className='text-center p-3'>
              <BsSearch color={colors.beplMagenta} size={45} />
            </Col>
            <Col xs={12} className='text-center font-weight-bold'>
              Server Error
            </Col>
          </Row>
          : (!loading && loadStatus === 'nonexistent') || (check && check.status !== 'Paid') ?
            /////////////////////////////////////////////////////////////////
            <Row style={{ position: 'relative', top: '90px' }}>
              <Col xs={12} className='text-center p-3'>
                <BsSearch color={colors.beplMagenta} size={45} />
              </Col>
              <Col xs={12} className='text-center font-weight-bold'>
                No Check For This Quarter
              </Col>
            </Row> :
            /////////////////////////////////////////////////////////////////
            <Row className="d-flex justify-content-center align-items-center h-25vh"><Spinner color='primary' /></Row>
      }
    </Container>
  );
}

export default RebateCheckDetails;