import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Row, Col, Button, Input } from 'reactstrap';
import { selectStyles } from '../../../../config/constants';
import { categoryDrilldown, itemDrilldown } from '../../../../reducers/DashboardReducer/DashboardActions';
import { setCompetitiveInsightItems, setMerchantPurchasesTypeView, setSearch, clearSearch, applySearch } from '../../../../reducers/IntelReducer/IntelActions';
import { Else, If, Then } from 'react-if';
import useMerchantData from './Merchant/useMerchantData';
import useItemData from './Item/useItemData';
import numeral from 'numeral';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaCalendarAlt } from 'react-icons/fa'
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import AdminUserSelect from './AdminView/AdminUserSelect';
import InputSearch from '../../../../sharedComponents/InputSearch/InputSearch';
import userApi from '../../../../api/userApi';
import { setUserData } from '../../../../reducers/UserReducer/UserActions';
import { spinner } from '../../../../reducers/UIReducer/UIActions';
import stringSanitizer from 'string-sanitizer';

const CompetitiveInsightHeader = (props) => {
  const dispatch = useDispatch();
  const categories = useSelector(({ intel }) => intel.competitiveInsightCategories);
  const items = useSelector(({ intel }) => intel.competitiveInsightItems);
  const ciData = useSelector(({ intel }) => intel.competitiveInsightData);
  const allLoaded = useSelector(({ intel }) => intel.allLoaded);
  const isInternalAdmin = useSelector(({ user }) => user.isInternalAdmin);
  const { exportMerchantsList } = useMerchantData.useCsvExport();
  const { exportItemsList } = useItemData.useCsvExport();
  const totalCompVolume = useMerchantData.useApi(props.categoryDrilldown, props.itemDrilldown).totalCompVolume;
  const summaryVolume = useSelector(({ intel }) => intel.summaryVolume);
  const fromDate = useSelector(({ intel }) => intel.fromDate);
  const toDate = useSelector(({ intel }) => intel.toDate);
  let dateRange = moment(fromDate).format('MMM Do YYYY') + ' - ' + moment(toDate).format('MMM Do YYYY');
  const { pathname } = useLocation();
  const splitpath = pathname.split('/');
  const merchant = splitpath[3];
  const merchantId = splitpath.includes('items') || splitpath.includes('orderhistory') || splitpath.includes('ordertocompetitor')
    ? splitpath[4] : splitpath.includes('matches') ? splitpath[6] : false;
  const itemForMatches = splitpath.includes('matches') ? splitpath[4] : false;
  const minForMatches = splitpath.includes('matches') ? splitpath[5] : false;
  const history = useHistory();
  const distributor = useSelector(({ user }) => user.activeDistributor);
  const distributorName = distributor && distributor.nickname ? (distributor.nickname.includes('-') ? distributor.nickname.split('-')[0] : distributor.nickname) : 'self';
  const search = useSelector(({ intel }) => intel.search);
  const userId = useSelector(({ user }) => user.id);
  const userList = useSelector(({ user }) => user.userRol);
  const impersonate = useSelector(({ user }) => user.impersonateAs);
  const merchantPurchasesTypeView = useSelector(({ intel }) => intel.merchantPurchasesTypeView);
  const activeDistributor = useSelector(({user}) => user.activeDistributor);
  const activeDistributorApply = useSelector(({user}) => user.activeDistributorApply);
  const currentUser = userList.find((u) => u.id === userId);
  const currentUserRole = currentUser?.title;

  const onApplyIntelSearch = () => {
    // need to clear this data onnly when date picker is used
    if (activeDistributor.id !== activeDistributorApply.id) {
      dispatch(spinner.add());
      userApi.activeDistributor(activeDistributor.id).post().then(({data}) => {
        dispatch(spinner.subtract());
        dispatch(applySearch());
        const { channelPartner } = data;
        dispatch(setUserData({channelPartner}));
      }).catch(err => {
        dispatch(spinner.subtract());
        console.error(err);
      });
    } else {
      dispatch(applySearch());
    }
  };

  const searchProps = {
    onChange: (e) => dispatch(setSearch(stringSanitizer.sanitize.keepSpace(e.target.value))),
    value: search,
    clearValue: () => dispatch(clearSearch()),
    onEnter: onApplyIntelSearch
  }

  const onCategoryChange = (categories) => {
    if (!categories) {
      dispatch(categoryDrilldown([]));
    }
    else {
      dispatch(categoryDrilldown(categories.map((cat) => cat.label)));
    }
  }

  const onDescriptionChange = (descriptions) => {
    if (!descriptions) {
      dispatch(itemDrilldown([]));
    }
    else {
      dispatch(itemDrilldown(descriptions.map((cat) => cat.label)));
    }
  }


  // Update item dropdown when data changes
  useEffect(() => {
    // reset volumes for all items
    let updatedItems = items.map((item) => {
      return {
        ...item,
        volume: 0
      };
    });

    // update volumes & sort descending
    ciData.forEach(item => {
      const foundItem = updatedItems.findIndex(uItem => uItem.item === item.description);

      // Set properties necessary for disabling/sorting in the dropdowns
      if (foundItem > -1) {
        updatedItems[foundItem].volume = updatedItems[foundItem].volume + item.purchase;
        updatedItems[foundItem].category = item.category;
        updatedItems[foundItem].merchant = item.merchant;
      }
    });
    updatedItems.sort((a, b) => b.volume - a.volume);

    dispatch(setCompetitiveInsightItems(updatedItems));
    //eslint-disable-next-line
  }, [dispatch, ciData]);

  const itemCategories = categories.map(i => {
    return { label: i.category, value: i.category };
  });

  const selectedCategories = props.categoryDrilldown.map(cat => {
    return { label: cat, value: cat };
  });

  const itemDescriptions = items.map(i => {
    return {
      label: i.item,
      value: i.item,
      category: i.category,
      volume: i.volume,
      merchant: i.merchant
    };
  });

  const selectedDescriptions = props.itemDrilldown.map(cat => {
    return { label: cat, value: cat };
  });

  const disableOption = (option) => {
    // if categories are selected, disable items not in those categories
    // also, if a detail merchant view is up, disable items not from that merchant
    const foundInCategories = selectedCategories.findIndex((cat) => option.category === cat.value) < 0;

    if (selectedCategories.length > 0) {
      if (!merchantId) {
        return foundInCategories;
      }
      else {
        return foundInCategories || (merchantId !== option.merchant);
      }
    }
    else {
      if (!merchantId) {
        return false;
      }
      else {
        return merchantId !== option.merchant;
      }
    }
  }

  let distributorSelect = null;
  if (props.distributorsList && props.distributorsList.length === 1) {
    // Show a single distributor as a label
    distributorSelect =
      <Col xs={isMobile ? 12 : ''} className={isMobile ? 'p-2' : 'w-fc'} style={{ paddingRight: 5 }}>
        <div className='main-header text-size-20'>{props.distributorsList[0].label}</div>
      </Col>
      ;
  }
  else {
    // Show multiple distributors as a select
    distributorSelect =
      <Col xs={isMobile ? 12 : ''} className={isMobile ? 'p-2' : 'w-fc mw-340px'} style={{ paddingRight: 5 }}>
        <Select
          options={props.distributorsList}
          onChange={props.selectActiveDistributor}
          styles={selectStyles}
          value={props.activeDistributor}
        />
      </Col>
      ;
  }
  const breadCrumbs = () => {
    if (props.distributorsList.length > 0) {
      return (
        <div className='d-flex flex-wrap font-weight-bold align-items-center'>
          {merchant ?
            <>
              <div onClick={() => history.push('/competitiveinsights/merchants')} className="clickable" >
                {props.distributorsList[0].label}
              </div>
              <div onClick={() => history.push('/competitiveinsights/items/' + merchant + '/' + merchantId)} className="clickable d-flex" >
                <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
                <div className={!itemForMatches && !pathname.includes('orderhistory')
                  && !pathname.includes('ordertocompetitor') ? 'text-beplMagenta' : ''}>{merchant}</div>
              </div>
            </> : false}
          {merchant && pathname.includes('ordertocompetitor') ?
            <div className='d-flex font-weight-bold'>
              <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
              <div onClick={() => history.push('/competitiveinsights/orderhistory/' + merchant + '/' + merchantId)} className='clickable'>{'Order History'}</div>
              <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
              <div className='text-beplMagenta clickable'>{'Competitor Order'}</div>
            </div> : false}
          {merchant && pathname.includes('orderhistory') ?
            <div className='d-flex font-weight-bold'>
              <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
              <div className='text-beplMagenta clickable'>{'Order History'}</div>
            </div> : false}
          {merchant && itemForMatches ?
            <div className='d-flex font-weight-bold'>
              <div className='text-sm px-1 text-beplBlue'>{` > `}</div>
              <div className='text-beplMagenta clickable'>{'Similar Items (' + minForMatches + ')'}</div>
            </div> : false}
        </div>
      )
    }
  }

  const setDateRange = (startDate, endDate) => {
    props.setDateRange({ fromDate: startDate._d, toDate: endDate._d });
    props.onApplySearch();
  }

  const setPurchasesType = (type) => {
    if (type.value) {
      dispatch(setMerchantPurchasesTypeView(type));
    } else {
      dispatch(setMerchantPurchasesTypeView({
        value: type,
        label: type === 'competition' ? 'Competitive Purchases' : distributorName + 'Purchases'
      }));
    }
  }
  return (
    <Col className='px-0'>
      <Row className={`pl-3 ${merchant ? 'py-3' : ''} d-flex justify-content-between`}>
        {breadCrumbs()}
        <If condition={pathname.includes('items')}>
          <div>
            <If condition={merchantId !== undefined && merchant !== undefined}>
              <Button onClick={() => history.push('/competitiveinsights/orderhistory/' + merchant + '/' + merchantId)} type="button" color="beplMagenta" className={`mr-3 py-2 rounded-sm btn-beplMagenta btn-sm button-label`} >Order History</Button>
            </If>
            <If condition={isInternalAdmin}>
              <Button type="button" disabled={!allLoaded} onClick={exportItemsList} color="beplMagenta" className={`mr-3 py-2 rounded-sm btn-beplMagenta btn-sm button-label`}>
                Export Page Details
              </Button>
            </If>
          </div>

        </If>
      </Row>
      <If condition={pathname.includes('merchants') || pathname.includes('items')}>
        <If condition={pathname.includes('merchants') || pathname.includes('items')}>
          <Then>
            <If condition={pathname.includes('merchants')}>
              <Row className={`${isMobile ? 'pl-3' : ''} main-header text-size-20 d-flex align-items-center position-relative justify-content-between`} style={{ marginTop: 16, paddingBottom: 6, zIndex: 1130 }}>
                <div className={`${isMobile ? '' : 'd-flex'}`}>{distributorSelect} | Total Competitor Volume {totalCompVolume > 0 ? numeral(totalCompVolume).format('$0,0.00') : ' $-.--'}</div>
                <If condition={isInternalAdmin}>
                  <Button type="button" disabled={!allLoaded} onClick={exportMerchantsList} color="beplMagenta" className={`mr-3 py-2 rounded-sm btn-beplMagenta btn-sm button-label`} style={{ float: 'right' }}>Export Page Details</Button>
                </If>
              </Row>
            </If>
            <If condition={pathname.includes('items')}>
              <Row className={`${isMobile ? 'pl-3' : ''} main-header text-size-20 d-flex align-items-center justify-content-between`} style={{ marginTop: 16, paddingBottom: 6, paddingLeft: 15 }}>
                <div>{merchant} Total Competitor Volume: {summaryVolume > 0 ? numeral(summaryVolume).format('$0,0.00') : ' $-.--'}</div>
                {!isMobile ? <div className={`p-0 d-flex align-items-center ${isMobile ? 'justify-content-center' : ''}`} style={{ marginTop: isMobile ? '10px' : '' }}>
                  <Col onClick={() => setPurchasesType('competition')} className={'font-weight-bold w-fc border-right text-size-16 pb-1 clickable text-' + (merchantPurchasesTypeView.value === 'competition' ? 'beplMagenta' : 'beplBlue')} >
                    Competitive Purchases
                  </Col>
                  <Col onClick={() => setPurchasesType('self')} className={'font-weight-bold w-fc border-left text-size-16 pb-1 clickable text-' + (merchantPurchasesTypeView.value === 'self' ? 'beplMagenta' : 'beplBlue')} >
                    {distributorName} Purchases
                  </Col>
                </div> : false}
              </Row>
            </If>
            {currentUserRole === 'Admin' ?
              <Row className='pb-3 align-items-center'>
                <Col xs={isMobile ? 12 : 3} style={{ zIndex: isMobile ? 1120 : 1010 }}>
                  <AdminUserSelect
                    userList={userList}
                    currentUser={currentUser}
                    impersonate={impersonate}
                  />
                </Col>
              </Row> : null
            }
            <Row className='label-text text-size-14 d-flex align-items-center' style={{ paddingTop: isMobile ? 0 : 6, paddingBottom: 16, paddingRight: 0 }}>
              <Col xs={isMobile ? 12 : 2} className={`${isMobile ? 'pt-2' : 'pr-1'}`} style={{ zIndex: 90 }}>
                <InputSearch {...searchProps} />
              </Col>

              {isMobile && pathname.includes('items') ? <Col xs={isMobile ? 12 : 3} className={`${isMobile ? 'pt-2' : ''}`} style={{ zIndex: 80 }}>
                <Select
                  placeholder='Purchased From'
                  options={[{ label: 'Competitive Purchases', value: 'competition' }, { label: distributorName + 'Purchases', value: 'self' }]}
                  onChange={setPurchasesType}
                  styles={selectStyles}
                  value={merchantPurchasesTypeView}
                  className={'w-100'}
                />
              </Col> : false}

              <Col xs={isMobile ? 12 : 3} className={`${isMobile ? 'pt-2' : 'pl-1 pr-1'}`} style={{ zIndex: 70 }}>
                <Select
                  isMulti
                  placeholder='All Categories'
                  options={itemCategories}
                  onChange={onCategoryChange}
                  styles={selectStyles}
                  value={selectedCategories}
                  className={'w-100'}
                />
              </Col>


              <Col xs={isMobile ? 12 : 4} className={`${isMobile ? 'pt-2' : 'px-1'}`} title={selectedDescriptions.value} style={{ zIndex: 60 }}>
                <Select
                  isOptionDisabled={(option) => disableOption(option)}
                  isMulti
                  placeholder='All Items'
                  options={itemDescriptions}
                  onChange={onDescriptionChange}
                  styles={selectStyles}
                  value={selectedDescriptions}
                  className={'w-100'}
                />
              </Col>
              <Col xs={isMobile ? 12 : 3} className={`${isMobile ? 'pt-2' : 'pl-1 pr-3'}`} style={{ zIndex: 50 }}>
                <DateRangePicker
                  onCallback={setDateRange}
                  initialSettings={{ startDate: fromDate, endDate: toDate }}
                >
                  <div className='position-relative'>
                    <Input type="text" readOnly value={dateRange} className="form-control" style={{ paddingLeft: '35px', backgroundColor: 'white' }} />
                    <FaCalendarAlt className="position-absolute-center-start" size="15px" />
                    <IoMdArrowDropdown className="position-absolute-center-end bg-white" size="25px" />
                  </div>

                </DateRangePicker>
              </Col>
            </Row>
          </Then>
          <Else>
          </Else>
        </If>
      </If>
    </Col>
  );
}

export default CompetitiveInsightHeader;